import axios from "./client";
import { SalesActions } from "../reducers/SalesInvoiceReducer";
import store from "./../store";
import FileDownload from "js-file-download";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchSales = async (month: string, year: string, enterprise: string) => {
  store.dispatch({ type: SalesActions.RequestSales, payload: {} });
  const { data: sales } = await axios.get<SalesInvoice[]>(
    `${API_URL}sales/${month}/${year}/${enterprise}`
  );
  store.dispatch({
    type: SalesActions.ReceiveSales,
    payload: sales,
  });
};

export const getSalesFromSII = async (month: string, year: string, enterprise: string) => {
  const response = await axios.get<boolean>(
    `${API_URL}sales/update/${month}/${year}/${enterprise}`
  );
  return response;
}

export const updateSale = async (id: string, sale: Partial<SalesInvoice>) => {
  const { data: updatedSale } = await axios.patch<SalesInvoice>(
    `${API_URL}sales/${id}`,
    sale
  );
  store.dispatch({
    type: SalesActions.UpdateSale,
    payload: updatedSale,
  });
};

export const getSalesSummary = async (month: string, year: string, enterprise:string) => {
  const { data: summary } = await axios.get<{
    exentTotal: number,
    netTotal: number,
    ivaTotal: number,
    total: number
  }>(
    `${API_URL}sales/summary/${month}/${year}/${enterprise}`
  );
  return summary;
}

export const generateSalesXls = async (month: string, year: string, enterprise:string) => {
  await axios.get(API_URL + `sales/download/${month}/${year}/${enterprise}`, {
    responseType: "blob",
    headers: {
      "content-type": "application/vnd.ms-excel;charset=UTF-8",
    },
  }).then((res) => {
    FileDownload(res.data, `Ventas-${month}-${year}.xlsx`);
  });
}


export const getNetAmountByCustomer = async (
  startMonth: number,
  startYear: number,
  endMonth: number,
  endYear: number,
  userRut: string
): Promise<Array<NetAmountByCustomer>> => {
  const response = await axios.get(API_URL + `sales/net-amount-by-customer`, {
    params: {
      startMonth,
      startYear,
      endMonth,
      endYear,
      userRut,
    },
  });
  return response.data;
};