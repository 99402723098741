import axios from "./client";
import { InvoiceActions } from "../reducers/InvoiceReducer";
import store from "./../store";
import FileDownload from "js-file-download";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchInvoices = async (month: string, year: string, enterprise: string) => {
  store.dispatch({ type: InvoiceActions.RequestInvoices, payload: {} });
  const { data: invoices } = await axios.get<Invoice[]>(
    `${API_URL}invoices/${month}/${year}/${enterprise}`
  );
  store.dispatch({
    type: InvoiceActions.ReceiveInvoices,
    payload: invoices,
  });
}

export const getInvoicesFromSII = async (month: string, year: string, enterprise: string) => {
  const response = await axios.get<boolean>(
    `${API_URL}invoices/update/${month}/${year}/${enterprise}`
  );
  return response;
}

export const updateInvoice = async (id: string, invoice: Partial<Invoice>) => {
  const { data: editedInvoice } = await axios.patch<Invoice>(
    `${API_URL}invoices/${id}`,
    invoice
  );
  store.dispatch({
    type: InvoiceActions.UpdateInvoice,
    payload: editedInvoice,
  });
};

export const getInvoicesSummary = async (month: string, year: string, enterprise: string) => {
  const { data: summary } = await axios.get<{
    exentTotal: number,
    netTotal: number,
    ivaTotal: number,
    total: number
  }>(
    `${API_URL}invoices/summary/${month}/${year}/${enterprise}`
  );
  return summary;
}

export const generateInvoicesXls = async (month: string, year: string, enterprise: string) => {
  await axios.get(API_URL + `invoices/download/${month}/${year}/${enterprise}`, {
    responseType: "blob",
    headers: {
      "content-type": "application/vnd.ms-excel;charset=UTF-8",
    },
  }).then((res) => {
    FileDownload(res.data, `Compras-${month}-${year}.xlsx`);
  });
};
