import { Box, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import MonthlyView from "./MonthlyView";

interface Props {
    year: string;
    type: "purchase" | "sale";
    enterprise: string;
}
const YearlyView: React.FC<Props> = ({ year, type, enterprise }) => {
    const [tab, setTab] = useState("01");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    return (
        <div className="h-full flex flex-col overflow-auto">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tab} onChange={handleChange} variant="scrollable">
                    <Tab label="Enero" value={"01"} />
                    <Tab label="Febrero" value={"02"} />
                    <Tab label="Marzo" value={"03"} />
                    <Tab label="Abril" value={"04"} />
                    <Tab label="Mayo" value={"05"} />
                    <Tab label="Junio" value={"06"} />
                    <Tab label="Julio" value={"07"} />
                    <Tab label="Agosto" value={"08"} />
                    <Tab label="Septiembre" value={"09"} />
                    <Tab label="Octubre" value={"10"} />
                    <Tab label="Noviembre" value={"11"} />
                    <Tab label="Diciembre" value={"12"} />
                </Tabs>
            </Box>
            <div className="flex-1 overflow-auto">
                <MonthlyView month={tab} year={year} type={type} enterprise={enterprise}></MonthlyView>
            </div>
        </div>
    )
}

export default YearlyView