import EnterpriseView from "../Invoices/EnterpriseView"
import { Box, Tabs, Tab } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";

const SalesView = () => {
    const [tab, setTab] = useState("");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };
    const { user } = useAppSelector((state) => state.authReducer);
    const enterprises = useMemo(() => user?.enterprises || [], [user]);
    useEffect(() => {
        if (enterprises.length > 0) {
            setTab(enterprises[0].SIIusername);
        }
    }, [enterprises]);
    return (
        <div className="p-5 h-full flex flex-col overflow-auto">
            <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="mb-3"
            >
                <Tabs value={tab} onChange={handleChange} variant="scrollable">

                    {enterprises.map((enterprise) => (
                        <Tab label={enterprise.SIIusername} value={enterprise.SIIusername} key={enterprise.SIIusername} />
                    ))}
                </Tabs>
            </Box>
            <div className="flex-1 overflow-auto">
                <EnterpriseView enterprise={tab} type={"sale"}></EnterpriseView>
            </div>
        </div>
    )
}

export default SalesView