import YearlyView from "../Invoices/YearlyView"
import { Box, Tabs, Tab } from "@mui/material";
import { useState } from "react";

interface Props {
    enterprise: string;
    type: "purchase" | "sale";
}
const EnterpriseView: React.FC<Props> = ({ enterprise, type }) => {
    const [tab, setTab] = useState("2024");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };
    return (
        <div className="p-5 h-full flex flex-col overflow-auto">
            <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="mb-3"
            >
                <Tabs value={tab} onChange={handleChange} variant="scrollable">
                    <Tab label="2022" value={"2022"} />
                    <Tab label="2023" value={"2023"} />
                    <Tab label="2024" value={"2024"} />
                    <Tab label="2025" value={"2025"} />
                </Tabs>
            </Box>
            <div className="flex-1 overflow-auto">
                <YearlyView year={tab} type={type} enterprise={enterprise}></YearlyView>
            </div>
        </div>
    )
}

export default EnterpriseView