import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "../../../hooks/useForm";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { updateSale } from "../../../services/SalesService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    sale: SalesInvoice | null;
}

const SalesStatusModal: React.FC<Props> = ({
    open,
    onClose,
    sale,
}) => {
    const initialValues = {
        invoiceDetails: "",
        observations: "",
        paymentStatus: "",
        paymentDate: new Date(),
        costCenter: "",
    };
    const [values, setValues, handleChange] = useForm(initialValues);
    useEffect(() => {
        if (sale !== null) {
            setValues({
                paymentStatus: sale.paymentStatus ? sale.paymentStatus : "",
                paymentDate: sale.paymentDate ? sale.paymentDate : new Date(),
                costCenter: sale.costCenter ? sale.costCenter : "",
                invoiceDetails: sale.invoiceDetails ? sale.invoiceDetails : "",
                observations: sale.observations ? sale.observations : "",
            });
        }
    }, [sale, setValues]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onTransitionEnd={() => !open && setValues(initialValues)}
        >
            <DialogTitle>Detalles Documento</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">
                    <TextField
                        onChange={handleChange}
                        id="costCenter"
                        label="Centro de Costo"
                        name="costCenter"
                        value={values.costCenter}
                        multiline
                        rows={4}
                    ></TextField>
                    <TextField
                        onChange={handleChange}
                        id="invoiceDetails"
                        label="Detalles de Factura"
                        name="invoiceDetails"
                        value={values.invoiceDetails}
                        multiline
                        rows={4}
                    ></TextField>
                    <TextField
                        onChange={handleChange}
                        id="observations"
                        label="Observaciones"
                        name="observations"
                        value={values.observations}
                        multiline
                        rows={4}
                    ></TextField>
                    <FormControl>
                        <InputLabel id="paymentStatus">Estado del Pago</InputLabel>
                        <Select
                            labelId="paymentStatus"
                            id="paymentStatus"
                            name="paymentStatus"
                            value={values.paymentStatus}
                            onChange={(e) => {
                                setValues({ ...values, paymentStatus: e.target.value });
                            }}
                        >
                            <MenuItem value={"Pagado"}>Pagado</MenuItem>
                            <MenuItem value={"Pendiente"}>Pendiente</MenuItem>
                            <MenuItem value={"Nula"}>Nula</MenuItem>
                            <MenuItem value={"N/A"}>N/A</MenuItem>

                        </Select>
                    </FormControl>
                    {values.paymentStatus === "Pagado" && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Fecha de pago"
                                inputFormat="dd/MM/yyyy"
                                value={values.paymentDate}
                                onChange={(newValue) => {
                                    setValues({ ...values, paymentDate: newValue! });
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button variant="contained"
                    onClick={async () => {
                        let valuesToSend;
                        if (values.paymentStatus === "Pagado") {
                            valuesToSend = values;
                        }
                        else {
                            valuesToSend = {
                                paymentStatus: values.paymentStatus,
                                costCenter: values.costCenter,
                                invoiceDetails: values.invoiceDetails,
                                observations: values.observations,
                            };
                        }
                        await updateSale(sale!._id, valuesToSend);
                        onClose();
                    }}
                >Guardar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SalesStatusModal

